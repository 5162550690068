<template>
  <div @click="handleClick">
    <input :accept="accept"
           :multiple="multiple"
           @change="handleChange"
           class="input-style"
           ref="input"
           type="file">
    <!--:webkitdirectory="webkitdirectory"-->
    <slot></slot>
  </div>
</template>

<script>
import bus from '../../utils/bus'

export default {
  name: 'DiskTypeUploadComponent',
  props: {
    multiple: {
      type: Boolean,
      default: true
    },
    webkitdirectory: {
      type: Boolean,
      default: false
    },
    folderId: {
      type: [Number, String],
      value: 0
    },
    folderGpId: [String, Number],
    folderName: String,
    uploadType: {
      type: Number,
      default: 1
    }
  },
  computed: {
    accept () {
      let type = ''
      switch (this.uploadType) {
        case 1:
          type = 'image/*'
          break
        case 2:
          type = 'video/*'
          break
        case 3:
          type = 'application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
          break
        case 4:
          type = 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.spreadsheetml.template,application/vnd.ms-excel.addin.macroEnabled.12'
          break
        case 5:
          type = 'application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation'
          break
        case 6:
          type = 'application/pdf'
          break
        case 7:
          type = 'image/*,video/*'
          break
        case 8:
          type = 'application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.spreadsheetml.template,application/vnd.ms-excel.addin.macroEnabled.12,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/pdf'
          break
        default:
          type = 'image/*'
          break
      }
      return type
    }
  },
  methods: {
    handleClick () {
      this.$refs.input.click()
    },
    handleChange (e) {
      const files = e.target.files
      if (!files) {
        return
      }
      bus.$emit('openSimpleUploader', {
        files,
        folderId: this.folderId,
        folderName: this.folderName,
        folderGpId: this.folderGpId,
        fileType: this.uploadType
      })
      this.$refs.input.value = null
    }
  }
}
</script>

<style lang="scss" scoped>
  .input-style {
    display: none;
  }
</style>

<template xmlns:v-clipboard="http://www.w3.org/1999/xhtml">
  <div class="h-full w-full bg-white">
    <div class="p-v-10 m-h-40 flex b-b">
      <disk-type-upload-component :folderGpId="folderType"
                                  :folderId="folderId"
                                  :uploadType="getUploadType"
                                  class="m-r-20"
                                  folderName="全部"
                                  v-if="isShowUpload">
        <Button class="upload-btn"
                type="primary">
          <Icon class="m-r-5"
                size="20"
                type="md-cloud-upload"/>
          上传
        </Button>
      </disk-type-upload-component>
      <!--<Button @click.stop="onClickCreateFolder"-->
      <!--class="newFolder m-r-20"-->
      <!--v-if="isShowCreateFolder">-->
      <!--<Icon size="20"-->
      <!--type="md-add-circle"/>-->
      <!--{{folderType === '2'?'新建相册':'新建分组'}}-->
      <!--</Button>-->

      <Button @click.stop="onClickFolderManger"
              class="newFolder m-r-20"
              v-if="isShowCreateFolder">
        <Icon size="20"
              type="md-add-circle"/>
        {{folderType === '2'?'相册管理':'分组管理'}}
      </Button>

      <ButtonGroup class="m-l-20" v-if="getSelectLength>0">
        <!--<Button v-if="getSelectLength === 1">分享</Button>-->
        <Button @click="onExpordClick" v-if="showDownLoad">下载</Button>
        <Button @click="onClickCollects" v-if="folderType === '3'">收藏</Button>
        <Button @click="onFileDelClick">删除</Button>
        <Button @click="onRenameClick" v-if="getSelectLength === 1 && getSelectList.id !== 0 ">重命名</Button>
        <Button @click="onMoveClick" v-if="canMoveSelecFile">移动到</Button>
        <Button style="display: none">标签</Button>
        <Button @click="onPermissionSet"
                v-if="getSelectLength === 1 && (parseInt(folderGroup) === 1 ) && breadcrumbList.length === 1">
          权限
        </Button>
      </ButtonGroup>
      <div class="flex-1"></div>
      <Input @on-search="onNameSearch" placeholder="搜索文件/文件夹" search style="width: 300px;" v-model="searchText"></Input>
    </div>
    <div class="flex-s-0 b-r p-h-40 fs-14"
         v-if="(parseInt(folderGroup) === 1) && breadcrumbList.length === 1">
      <ul class="flex b-b">
        <li :class="{'actives-item-style':currectIndex === item.id}" :key="index" @click="onMenuItemClick(item)"
            class="h-30 lh-30 m-t-10 p-h-20"
            v-for="(item,index) in innerMenus">
          <div :class="{'actives-items-style':currectIndex === item.id}">{{item.name}}</div>
        </li>
      </ul>
    </div>
    <div class="flex-s-0 b-r p-h-40 fs-14" v-if="parseInt(folderGroup) === 1 && breadcrumbList.length > 1">
      <ul class="flex b-b">
        <li :class="{'actives-item-style':subcurrectIndex === item.id}" :key="index" @click="onSecMenuItemClick(item)"
            class="h-30 lh-30 m-t-10 p-h-20"
            v-for="(item,index) in innersecMenu">
          <div :class="{'actives-items-style':subcurrectIndex === item.id}">{{item.name}}</div>
        </li>
      </ul>
    </div>
    <div class="flex-s-0 b-r p-h-40 fs-14" v-if="parseInt(folderGroup) === 5">
      <ul class="flex b-b">
        <li :class="{'actives-item-style':subcurrectIndex === item.id}" :key="index" @click="onSecMenuItemClick(item)"
            class="h-30 lh-30 m-t-10 p-h-20"
            v-for="(item,index) in innerfilesecMenu">
          <div :class="{'actives-items-style':subcurrectIndex === item.id}">{{item.name}}</div>
        </li>
      </ul>
    </div>

    <div class="flex w-full" style="height: calc(100% - 1px);">
      <div class="flex-1 o-y-auto noscroll" ref="scrollview">
        <div class="flex a-center p-h-40 m-v-10 m-l-10">
          <div class="flex-1 v-a-middle fs-14 flex">
            <span class="bold" v-if="breadcrumbList.length === 1">{{getFolderName}}</span> <span
            v-if="parseInt(folderGroup) === 1 && breadcrumbList.length === 1"> > {{getshowLable}}</span>
            <span v-if="breadcrumbList.length > 1">
              <span @click="onBackClick"
                    style="color: #1385EA">返回上一级</span> | <span>{{breadcrumbList[breadcrumbList.length-1].name}}</span></span>
          </div>
          <Input class="m-r-30" placeholder="跳转路径" readonly style="width: 600px;display: none" v-model="copyUrl">
                  <span slot="append"
                        v-clipboard:copy="copyUrl"
                        v-clipboard:error="onError"
                        v-clipboard:success="copy">复制链接</span>
          </Input>
          <div class="flex a-center flex-s-0 m-l-10">
            <img @click="onShowTypeChange(1)" class="w-20 h-20" src="../../assets/common/ic_liebiao_noselected.png"
                 v-if="!isList">
            <img @click="onShowTypeChange(1)" class="w-20 h-20" src="../../assets/common/ic_liebiao_selected.png"
                 v-if="isList">
            <img @click="onShowTypeChange(0)" class="w-20 h-20 m-l-10"
                 src="../../assets/common/ic_tuwen_selected.png"
                 v-if="!isList">
            <img @click="onShowTypeChange(0)" class="w-20 h-20 m-l-10"
                 src="../../assets/common/ic_tuwen_noselected.png"
                 v-if="isList">
          </div>
        </div>
        <div class="p-h-40">
          <div v-if="isList">
            <!--:height="tableHeight"-->
            <Table :columns="getColumns" :data="dataList" @on-row-click="onTableItemClick"
                   @on-selection-change="onSelectionChange">

              <template slot="operation" slot-scope="{ row, index }">
                <slot :item="row" name="minopera">
                  <div @click.stop class="flex a-center j-center">
                    <img alt="" class="w-20 h-20"
                         src="../../assets/source/ic_link.png"
                         style="display: none"
                         v-clipboard:copy="getCopyLink(row)"
                         v-clipboard:error="onError"
                         v-clipboard:success="copy">
                    <img :src="showCollecStatus(row)" @click="onCollectClick(row)" alt="" class="w-20 h-20 m-l-25"
                         v-if="row.type !== -1 && row.content.type !== 1 && row.content.type !== 2">

                    <img @click.stop="onFileEdit(row,index)" alt="" class="w-20 h-20 m-l-25"
                         src="../../assets/source/ic_xiugai.png">
                    <img @click="ondel(row)" alt="" class="w-20 h-20 m-l-25" src="../../assets/source/ic_del_th.png">
                  </div>
                </slot>
              </template>
              <template slot="name" slot-scope="{ row,index }">
                <div class="flex a-center">
                  <file-icon :file="row"
                             class="m-r-10"/>
                  <span v-show="!row.isEdit">{{row.name}}</span>
                  <div @click.stop class="rename"
                       v-show="row.isEdit">
                    <Input :ref="'input'+index"
                           @on-focus="onFocus($event)"
                           v-model="dataList[index].editName"/>
                    <img @click.stop="onClickRenameOk(dataList[index])"
                         src="../../assets/common/addfolder_icon_ok.png">
                    <img @click.stop="onClickRenameCancle(dataList[index],index)"
                         src="../../assets/common/addfolder_icon_cancle.png">
                  </div>
                </div>
              </template>
              <template slot="checkbox"
                        slot-scope="{ row,index }">
                <div @click.stop>
                  <Checkbox @on-change="onSelectionChange"
                            v-model="dataList[index].ischeck"></Checkbox>
                </div>
              </template>
            </Table>
          </div>
          <div class="p-h-15" v-if="!isList">
            <ul class="flex m-l--15 m-r--15 flex-wrap">
              <li :key="index" @click="onSourceItemClick(item)" v-for="(item,index) in dataList">
                <div class="p-10 m-5 item-style relative">
                  <div class="w-120 m-v-5 relative" style="height: 120px;" v-if="showAlbums(item)">
                    <img :src="getCoverUrl(item)" alt="" class="h-full" style="width: 118px">
                    <div class="h-full flex a-center j-center absolute left-0 top-0" style="width: 118px"
                         v-if="item.type === 2">
                      <img alt="" class="w-24 h-24" src="../../assets/common/imgview_icon_player.png">
                    </div>
                  </div>
                  <div class="w-120 m-v-5 relative flex a-center j-center" style="height: 120px;"
                       v-if="!showAlbums(item)">
                    <img :src="getCoverUrl(item)" alt="" style="width: 52px;height:65px">
                  </div>
                  <div class="w-120 lin2" style="word-break: break-all;margin-top: 0px;"
                       v-if="!item.isEdit">
                    {{item.name}}
                  </div>
                  <div @click.stop class="w-120" style="word-break: break-all;margin-top: 0px;"
                       v-if="item.isEdit">
                    <Input v-model="item.editName"></Input>
                    <div class="flex j-end a-center">
                      <img @click.stop="onClickRenameOk(item)"
                           src="../../assets/common/addfolder_icon_ok.png">
                      <img @click.stop="onClickRenameCancle(item,index)"
                           src="../../assets/common/addfolder_icon_cancle.png">
                    </div>
                  </div>
                  <!--<div class="clear w-full p-h-10">-->
                  <!--<div class="fr" style="color:#257DE5;display: none"-->
                  <!--v-clipboard:copy="item.copylink"-->
                  <!--v-clipboard:error="onError"-->
                  <!--v-clipboard:success="copy">复制链接-->
                  <!--</div>-->
                  <!--</div>-->
                  <div :selectMode="showSelect" @click.stop class="absolute left-5 top-5 checkstyle">
                    <checkbox v-model="item.ischeck"></checkbox>
                  </div>
                  <div class="absolute right-5 top-5" v-if="getshowTypeIcon(item)">
                    <img :src="getshowIcon(item)" alt="" class="w-20 h-20">
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <infinite-loading :distance="10"
                          @infinite="infiniteHandler"
                          ref="infiniteLoading">
          <div class="total"
               slot="no-more">共{{dataList.length}}项
          </div>
        </infinite-loading>
      </div>
    </div>
    <Modal @on-visible-change="onModalMangerClose"
           class="modal"
           class-name="vertical-center-modal"
           footer-hide
           v-model="showFolderMager"
           width="900">
      <div>
        <div class="b-b h-50 p-h-30 lh-50">
          <span> {{folderGroup === '1'?'相册管理':'分组管理'}}</span>
        </div>
        <div class="p-b-30">
          <file-group-manger :dataChange="onGroupChange" :folderGroup="folderGroup"
                             :folderType="folderType"
                             :open="showFolderMager"></file-group-manger>
        </div>
      </div>
    </Modal>
    <Modal @on-visible-change="onModalClose"
           class="modal"
           class-name="vertical-center-modal"
           footer-hide
           v-model="showFolder"
           width="600">
      <div>
        <div class="b-b h-50 p-h-30 lh-50">
          <span v-if="selEditFloderId>0"> {{folderGroup === '1'?'编辑相册':'编辑分组'}}</span>
          <span v-if="selEditFloderId<1">{{folderGroup === '1'?'新建相册':'新建分组'}}</span>
        </div>
        <div class="p-h-50 m-t-20">
          <Input placeholder="输入文本" v-model="inputText"></Input>
        </div>
        <div class="p-h-50 m-t-15">
          <div class="fs-12">
            {{folderGroup === '1'?'相册':'分组'}}权限设置
          </div>
          <div class="fs-14 m-t-10">
            <RadioGroup v-model="chooseType">
              <Radio :label="1" class="m-r-15">公开</Radio>
              <Radio :label="3" class="m-r-15">私密</Radio>
              <Radio :label="2">部分公开</Radio>
            </RadioGroup>
          </div>
        </div>
        <div class="p-h-50 m-t-15" v-if="chooseType === 2">
          全选/全不选 <input type="checkbox" v-model="select">
          <ul class="flex a-center flex-wrap p-10" style="background:#F8F8F9">
            <li :key="index" class="w-p-25 flex-s-0 o-hidden" style="white-space: nowrap"
                v-for="(item,index) in userList">
              <input type="checkbox" v-model="item.flag">
              {{item.name}}
            </li>
          </ul>
        </div>
        <div class="flex a-center j-center p-v-20">
          <Button @click="onFloderSaveClick">确定</Button>
          <Button @click="showFolder=false" class="m-l-20">取消</Button>
        </div>
      </div>
    </Modal>
    <disk-type-upload-task @uploadSuccess="onUploadSuccess"></disk-type-upload-task>
    <Modal @on-visible-change="onModalsClose"
           class="modal"
           class-name="vertical-center-modal"
           footer-hide
           fullscreen
           v-model="showSrcShow">

      <div class="flex a-center j-center w-full h-full" style="background:#0e0e0e ;">
        <iframe :src="picshowUrl" class="w-full" frameborder="0" noresize ref="logframe"
                style="height:calc(100% - 1px)">

        </iframe>
      </div>
    </Modal>
    <Modal :mask-closable="false"
           @on-visible-change="onModalsMoveClose"
           class="modal"
           class-name="vertical-center-modal"
           footer-hide
           v-model="showMoveDialog"
           width="400">
      <div>
        <div class="b-b h-50 p-h-30 lh-50">
          <span>移动到</span>
        </div>
        <div class="m-h-30">
          <div class="p-t-10 p-b-5">
            <span>文件移动到</span>
          </div>
          <div class="border o-y-auto" style="max-height: 500px;min-height: 100px">
            <Tree :data="data1" @on-select-change="onTreeItemSelect"></Tree>
          </div>
          <div class="flex j-end p-v-10">
            <Button @click="onMoveConfigClick">确定</Button>
            <Button @click="showMoveDialog = false" class="m-l-15">取消</Button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import {mapActions} from 'vuex'
import {deleteFolderAndFileToRecycle, uploadFile} from '../../services/diskapi'
import {handleBatchDownload, handleSimpleDownload} from '../../utils/filedown'
import FileIcon from '../../components/common/FileIcon'
import DiskTypeUploadComponent from '../../components/disk/DiskTypeUploadComponent'
import DiskTypeUploadTask from '../../components/disk/DiskTypeUploadTask'
import FileGroupManger from '../../components/disk/FileGroupManger'

export default {
  name: 'Albums',
  components: {
    FileGroupManger,
    DiskTypeUploadTask,
    DiskTypeUploadComponent,
    FileIcon,
  },
  props: {
    menulist: Array
  },
  data () {
    return {
      showFolder: false,
      showSrcShow: false,
      showFolderMager: false,
      searchText: '',
      myId: 0,
      picshowUrl: '',
      chooseType: 1,
      inputText: '',
      copyUrl: '',
      folderName: '',
      folderGroup: 0,
      folderId: 0,
      columns: [{
        type: 'selection',
        width: 60,
        align: 'center'
      }, {
        title: '分组',
        key: 'groupName',
        width: 100,
        // sortable: 'custom',
        tooltip: true,
      }, {
        title: '文件名称',
        align: 'left',
        key: 'name',
        className: 'demo-table-info-column',
        slot: 'name'
      }, {
        title: '创建时间',
        align: 'left',
        width: 210,
        key: 'uploadTime'
      }, {
        title: '文件大小',
        align: 'center',
        width: 160,
        key: 'sizes'
      }, {
        title: '创建人',
        align: 'left',
        width: 160,
        key: 'creater'
      }, {
        title: '权限',
        align: 'center',
        width: 160,
        key: 'persiton'
      }, {
        title: '操作',
        align: 'center',
        width: 160,
        slot: 'operation',
      }],
      dataList: [],
      tableHeight: 750,
      innerMenus: [{
        id: 0,
        name: '全部'
      }, {
        id: 1,
        name: '公开'
      }, {
        id: 3,
        name: '私密'
      }, {
        id: 2,
        name: '部分可见'
      }],
      innersecMenu: [
        {
          id: 0,
          name: '全部'
        }, {
          id: 1,
          name: '照片'
        }, {
          id: 2,
          name: '视频'
        }
      ],
      innerfilesecMenu: [
        {
          id: 0,
          name: '全部'
        }, {
          id: 3,
          name: 'DOC'
        }, {
          id: 4,
          name: 'XLS'
        }, {
          id: 5,
          name: 'PPT'
        }, {
          id: 6,
          name: 'PDF'
        }
      ],
      currectIndex: 0,
      subcurrectIndex: 0,
      userList: [],
      breadcrumbList: [],
      pageNum: 1,
      pageSize: 20,
      isMoreFolder: true,
      isMoreFile: true,
      manualResetState: false,
      selEditFloderId: 0,
      moveinfo: null,
      showMoveDialog: false,
      data1: [
        {
          id: 0,
          title: '全部',
          expand: true,
          selected: false,
          children: []
        }
      ],
      selFloderInfo: null,
      permissionType: 0,
      folderType: 0
    }
  },
  watch: {
    '$route': {
      handler (newValue) {
        if (newValue.params.type) {
          this.subcurrectIndex = 0
          this.currectIndex = 0
          this.permissionType = 0
          this.folderGroup = newValue.params.type
          this.folderType = newValue.query.folderType
        }
      },
      deep: true,
      immediate: true
    },
    folderGroup: {
      handler (newValue) {
        if (parseInt(newValue) > 0) {
          this.folderId = 0
          this.breadcrumbList = []
          this.breadcrumbList.push({
            id: this.folderGroup,
            folderid: this.folderId,
            name: this.folderName
          })
          this.getSelFolderList()
          this.reloadData()
        } else {
          this.dataList = []
        }
      },
      immediate: true
    },
    getCopyUrl: {
      handler (newValue) {
        if (newValue.menulist && newValue.menulist.length && newValue.folderGroup) {
          let abluslist = newValue.menulist[1].child || []
          let filelist = newValue.menulist[2].child || []
          let menulist = abluslist.concat(filelist)
          let list = menulist.filter(res => {
            return parseInt(res.id) === parseInt(this.folderGroup)
          })
          if (list && list.length) {
            this.copyUrl = list[0].link
            this.folderName = list[0].name
          }
        }
      },
      deep: true,
      immediate: true
    },
    folderInfo: {
      handler (newValue) {
        this.reloadData()
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    getColumns () {
      if (parseInt(this.folderType) === 2 || parseInt(this.folderType) === 4 || parseInt(this.folderType) === 5) {
        return this.columns.filter(res => {
          return res.key !== 'groupName'
        })
      }
      return this.columns
    },
    getFolderName () {
      if (this.folderName) {
        return this.folderName
      } else {
        switch (parseInt(this.folderType)) {
          case 3:
            return '文档'
          case 2:
            return '相册'
          case 4:
            return '图片'
          case 5:
            return '视频'
        }
      }

    },
    folderInfo () {
      return {
        folderId: this.folderId,
        level: this.breadcrumbList.length,
        permissionType: this.currectIndex
      }
    },
    showDownLoad () {
      if (this.breadcrumbList.length > 1) {
        return true
      } else if (this.breadcrumbList.length === 1 && parseInt(this.folderGroup) !== 1) {
        return true
      } else {
        return false
      }
    },
    isShowCreateFolder () {
      if (this.breadcrumbList.length === 1 && (parseInt(this.folderGroup) === 1 || parseInt(this.folderGroup) === 5)) {
        return true
      }
      return false
    },
    isShowUpload () {
      if (this.breadcrumbList.length > 1 || (this.breadcrumbList.length === 1 && (parseInt(this.folderGroup) !== 1))) {
        return true
      }
      return false
    },
    showSelect () {
      if (this.dataList && this.dataList.length) {
        let list = this.dataList.filter(res => {
          return res.ischeck
        })
        if (list && list.length) {
          return true
        }
      }
      return false
    },
    canMoveSelecFile () {
      let list = this.dataList.filter(res => {
        return res.ischeck
      })
      if (list && list.length) {
        let floderlist = list.filter(res => {
          return res.type === -1
        })
        if (floderlist && floderlist.length) {
          return false
        } else if (this.breadcrumbList.length < 2) {
          if (parseInt(this.folderGroup) === 5) {
            return true
          }
          return false
        } else {
          return true
        }
      }
      return false
    },
    getSelectLength () {
      let length = 0
      if (this.dataList && this.dataList.length) {
        let list = this.dataList.filter(res => {
          return res.ischeck
        })
        if (list && list.length) {
          length = list.length
        }
      }
      return length
    },
    getSelectList () {
      let info = {
        id: 0
      }
      if (this.dataList && this.dataList.length) {
        let list = this.dataList.filter(res => {
          return res.ischeck
        })
        if (list && list.length) {
          info = list[0]
        }
      }
      return info
    },
    getUploadType () {
      if (parseInt(this.folderGroup) === 4) {
        return 2
      } else if (parseInt(this.folderGroup) === 1 || parseInt(this.folderGroup) === 2) {
        return 7
      } else if (parseInt(this.folderGroup) === 3) {
        return 1
      } else if (parseInt(this.folderGroup) === 5) {
        return 8
      } else if (parseInt(this.folderGroup) === 6) {
        return 3
      } else if (parseInt(this.folderGroup) === 7) {
        return 4
      } else if (parseInt(this.folderGroup) === 8) {
        return 5
      } else if (parseInt(this.folderGroup) === 9) {
        return 6
      } else {
        return 1
      }
    },
    getshowLable () {
      let text = ''
      let list = this.innerMenus.filter(res => {
        return res.id === this.currectIndex
      })
      if (list && list.length) {
        text = list[0].name
      }
      return text
    },
    isList () {
      return parseInt(this.$store.state.disk.isShowList)
    },
    getCopyUrl () {
      return {
        folderGroup: this.folderGroup,
        menulist: this.menulist
      }
    },
    select: {
      // 显示修改后的数据
      get () {
        return this.userList.every((item) => item.flag)
      },
      // 修改数据  传参
      set (v) {
        this.userList.forEach((item) => item.flag = v)
      },
    },
    isCheckAll () {
      if (this.dataList.length === 0) {
        return false
      } else {
        let selList = this.dataList.filter(res => {
          return res.ischeck
        })
        if (selList.length === this.dataList.length) {
          return true
        } else {
          return false
        }
      }
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.tableHeight = window.innerHeight - 130
      window.onresize = () => {
        this.tableHeight = window.innerHeight - 130
      }
      this.myId = this.$store.state.user.userId
      for (const i in this.columns) {
        let item = this.columns[i]
        if (item.type === 'selection') {
          this.$delete(item, 'type')
          this.$set(item, 'slot', 'checkbox')
          this.$set(item, 'renderHeader', (h, params) => {
            return h('Checkbox', {
              props: {
                value: this.isCheckAll,
              },
              on: {
                'on-change': this.onChangeCheckAll
              }
            })
          })
        }
      }
      this.getEmployeeLists()
    },
    onGroupChange () {
      this.getSelFolderList()
    },
    async onPermissionSet () {
      if (this.dataList && this.dataList.length) {
        let list = this.dataList.filter(res => {
          return res.ischeck
        })
        if (list && list.length) {
          let id = list[0].id
          let result = await this.getFolderDetail(id)
          if (result.code === 0) {
            let data = result.data
            this.inputText = data.name
            this.chooseType = data.permissionType || 1
            let persons = data.userList.map(res => {
              return res.id
            })
            this.userList.map(res => {
              if (persons.indexOf(res.id) !== -1) {
                res.flag = true
              } else {
                res.flag = false
              }
            })
            this.selEditFloderId = id
            this.showFolder = true
          }
        }
      }
    },
    onMoveClick () {
      if (this.dataList && this.dataList.length) {
        let list = this.dataList.filter(res => {
          return res.ischeck
        })
        if (list && list.length) {
          this.moveinfo = list
          this.showMoveDialog = true
        }
      }

    },
    getCopyLink (row) {
      let text = ''
      if (row.link && row.link.link) {
        text = row.link.link || ''
      }
      return text
    },
    getFileSuffix (name) {
      let resultstr = ''
      let index = name.lastIndexOf('.')
      if (index > 0) {
        resultstr = name.substring(index)
      }
      return resultstr
    },
    onNameSearch () {
      this.reloadData()
    },
    async onMoveConfigClick () {
      let info = this.selFloderInfo
      if (!info || info.id < 1) {
        return this.$Message.info('无法移动')
      }
      if ((this.selFloderInfo.permissionType === 3 && this.permissionType === 3) || (this.selFloderInfo.permissionType !== 3)) {
        let list = this.moveinfo.map(res => {
          return {
            id: res.id
          }
        })
        let params = {
          folderId: this.selFloderInfo.id,
          folderType: this.selFloderInfo.folderType,
          documentList: list
        }
        let res = await this.tansferToFloder(params)
        if (res.code === 0) {
          this.reloadData()
          this.showMoveDialog = false
        } else {
          this.$Message.info(res.msg)
        }
      } else {
        let mylist = this.moveinfo.filter(res => {
          return res.uploadUser.id === this.myId
        }).map(res => {
          return {
            id: res.id
          }
        })

        let otherlist = this.moveinfo.filter(res => {
          return res.uploadUser.id !== this.myId
        }).map(res => {
          res.content.contentId = 0
          res.content.path = res.content.name || ''
          res.content.suffix = this.getFileSuffix(res.content.name)
          return res.content
        })

        if (mylist && mylist.length) {
          let params = {
            folderId: this.selFloderInfo.id,
            folderType: this.selFloderInfo.folderType,
            documentList: mylist
          }
          let res = await this.tansferToFloder(params)
          if (res.code === 0) {
            if (!(otherlist && otherlist.length)) {
              this.reloadData()
              this.showMoveDialog = false
            }
          } else {
            this.$Message.info(res.msg)
          }
        }
        console.log('otherlist===>', otherlist)
        if (otherlist && otherlist.length) {
          let params = {
            folderType: this.selFloderInfo.folderType,
            folderId: this.selFloderInfo.id,
            contentList: otherlist
          }
          uploadFile(params).then(res => {
            if (res.code === 0) {
              this.reloadData()
              this.showMoveDialog = false
            } else {
              this.$Message.info(res.msg)
            }
          })
        }
      }
    },
    getshowIcon (item) {
      let permissionType = item.permissionType || 0
      let result = ''
      switch (permissionType) {
        case 1:
          result = require('../../assets/common/icon_public.png')
          break
        case 3:
          result = require('../../assets/common/icon_private.png')
          break
        case 2:
          result = require('../../assets/common/icon_apart.png')
          break
      }
      return result
    },
    getshowTypeIcon (item) {
      let permissionType = item.permissionType || 0
      return permissionType > 0
    },
    onChangeCheckAll (boo) {
      this.dataList.forEach(item => {
        item.ischeck = boo
      })
    },
    infiniteHandler ($state) {
      this.$nextTick(() => {
        this.getFolderLists($state)
      })
    },
    reloadData () {
      this.dataList = []
      this.pageNum = 1
      this.$nextTick(() => {
        this.$refs.infiniteLoading.$emit('$InfiniteLoading:reset')
      })
    },
    getCoverUrl (item) {
      if (item.type === -1) {
        if (item.coverInfo && item.coverInfo.url) {
          if (item.coverInfo.type === 2) {
            return item.coverInfo.url + '?x-oss-process=video/snapshot,t_1000,ar_auto'
          } else if (item.coverInfo.type === 1) {
            return item.coverInfo.url
          } else {
            return require('../../assets/common/ic_wenjianjia.png')
          }
        } else {
          return require('../../assets/common/ic_wenjianjia.png')
        }
      } else if (item.type === 5) {
        return require('../../assets/source/imgview_icon_ppt.png')
      } else if (item.type === 3) {
        return require('../../assets/source/imgview_icon_word.png')
      } else if (item.type === 4) {
        return require('../../assets/source/imgview_icon_excle.png')
      } else if (item.type === 6) {
        return require('../../assets/source/imgview_icon_pdf.png')
      } else {
        return item.coverUrl
      }
    },

    getEmployeeLists () {
      let params = {
        pageNum: 1,
        pageSize: 1000
      }
      this.getEmployeeList(params).then(res => {
        if (res.code === 0) {
          this.userList = res.data.list.filter(child => {
            return child.id !== this.myId
          }).map(child => {
              child.role = this.getRole(child.roleType)
              child.flag = false
              return child
            }
          )
        }
      })
    },
    getRole (type) {
      let roleName = ''
      switch (type) {
        case 1:
          roleName = '超级管理员'
          break
        case 2:
          roleName = '管理员'
          break
        case 3:
          roleName = '普通用户'
          break
      }
      return roleName
    },
    onModalClose (status) {
      if (!status) {
        this.inputText = ''
        this.chooseType = 1
        this.selEditFloderId = 0
        this.userList.map(res => {
          res.flag = false
        })
      }
    },
    onModalMangerClose (status) {
      if (!status) {
        this.reloadData()
      }
    },
    onClickCreateFolder () {
      this.showFolder = true
    },
    onClickFolderManger () {
      this.showFolderMager = true
    },
    onFloderSaveClick () {
      let userList = this.userList.filter(res => {
        return res.flag
      }).map(res => {
        return {
          id: res.id
        }
      })
      if (userList && userList.length) {
        let user = new Set()
        userList.map(child => {
          user.add(child.id)
        })
        if (!user.has(this.myId)) {
          userList.push({
            id: this.myId
          })
        }
      } else {
        userList.push({
          id: this.myId
        })
      }

      if (this.selEditFloderId > 0) {
        let id = this.selEditFloderId
        let params = {
          folderGroupId: parseInt(this.folderGroup),
          name: this.inputText || '',
          permissionType: this.chooseType || 1,
          userList: userList || []
        }
        this.editFolders({
          id,
          params
        }).then(res => {
          if (res.code === 0) {
            this.reloadData()
            this.showFolder = false
          } else {
            this.$Message.info(res.msg)
          }
        })
      } else {
        let params = {
          folderType: this.folderType,
          folderGroupId: parseInt(this.folderGroup),
          parentId: 0,
          name: this.inputText,
          permissionType: this.chooseType || 1,
          desc: '',
          userList: userList || []
        }
        this.addFolder(params).then(res => {
          if (res.code === 0) {
            this.reloadData()
            this.showFolder = false
          } else {
            this.$Message.info(res.msg)
          }
        })
      }
    },
    async getSelFolderList () {
      let params = {
        folderType: this.folderType,
        folderGroupId: parseInt(this.folderGroup),
        parentId: 0,
        permissionType: 0
      }
      let res = await this.getfolderList(params)
      if (res.code === 0) {
        let data1 = [
          {
            id: 0,
            title: '全部',
            expand: true,
            children: [],
            selected: false,
          }
        ]
        data1[0].children = res.data.map(resl => {
          return {
            id: resl.id,
            title: resl.name,
            permissionType: resl.permissionType,
            folderType: resl.folderType,
            expand: true,
            selected: false,
          }
        })
        this.data1 = data1
      }
    },
    async getFolderLists ($state) {
      if (this.breadcrumbList.length === 1 && (parseInt(this.folderGroup) === 1)) {
        let params = {
          folderType: this.folderType,
          parentId: this.folderId
        }
        if (this.currectIndex > 0) {
          params.permissionType = this.currectIndex
        }
        if (this.searchText) {
          params.name = this.searchText.replace(/[\[\]\^%\<>\\|*?,{}/]/g, '')
        }
        let res = await this.getfolderList(params)
        if (res.code === 0) {
          this.dataList = res.data.map((resl, index) => {
            resl.index = index + 1
            resl.copylink = resl.link ? resl.link.link || '' : ''
            resl.type = -1
            resl.objType = 2
            resl.ischeck = false
            resl.editName = resl.name || ''
            resl.suffix = ''
            resl.isEdit = false
            resl.sizes = '-'
            let createUser = resl.uploadUser
            resl.creater = createUser ? createUser.name || '' : ''
            // resl.coverUrl = resl.coverInfo.url
            let permissionType = '-'
            switch (resl.permissionType) {
              case 1:
                permissionType = '公开'
                break
              case 3:
                permissionType = '私密'
                break
              case 2:
                permissionType = '部分公开'
                break
            }
            resl.persiton = permissionType
            return resl
          })
        } else {
          this.$Message.info(res.msg)
        }
        $state.loaded()
        $state.complete()
      } else {
        this.getFilesLists($state)
      }
    },
    onSelectionChange (e) {

    },
    onShowTypeChange (type) {
      this.setMenuShowList(type)
    },
    copy (e) {
      this.$Message.info('复制成功')
    },
    onError (e) {
      this.$Message.info('复制成失败')
    },
    onMenuItemClick (item) {
      this.searchText = ''
      if (this.breadcrumbList.length > 1) {
        this.folderId = 0
        this.breadcrumbList = []
        this.breadcrumbList.push({
          id: this.folderGroup,
          folderid: this.folderId,
          name: this.folderName
        })
      }
      this.currectIndex = item.id
    },
    onSecMenuItemClick (item) {
      this.searchText = ''
      this.subcurrectIndex = item.id
      this.pageNum = 1
      this.reloadData()
    },
    onTableItemClick (item) {
      this.onSourceItemClick(item)
    },
    onRowDblclick () {

    },
    // input聚焦
    onFocus (e) {

    },
    onSourceItemClick (item) {
      if (item.isEdit) {
        return
      }
      console.log(item)
      if (item.type === -1) {
        this.folderId = item.id
        this.permissionType = item.permissionType
        this.breadcrumbList.push({
          id: this.folderGroup,
          folderid: this.folderId,
          name: item.name,
          permissionType: item.permissionType,
          folderType: item.folderType,
        })
      } else {
        if (item.coverUrl && item.type === 1) {
          this.picshowUrl = 'http://47.104.70.223:8012/onlinePreview?url=' + encodeURIComponent(this.$BaseSix.encode(item.coverUrl))
          this.showSrcShow = true
        } else if (item.content.url) {
          this.picshowUrl = 'http://47.104.70.223:8012/onlinePreview?url=' + encodeURIComponent(this.$BaseSix.encode(item.content.url))
          this.showSrcShow = true
        }
      }
    },
    async getFilesLists ($state) {
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        folderType: this.folderType
      }
      if (this.folderId) {
        params.folderId = this.folderId
      }
      if (this.currectIndex > 0) {
        params.permissionType = this.currectIndex
      }
      if (this.subcurrectIndex > 0) {
        params.type = this.subcurrectIndex
      }
      if (this.searchText) {
        params.name = this.searchText.replace(/[\[\]\^%\<>\\|*?,{}/]/g, '')
      }
      let res = await this.getFileList(params)
      if (res.code === 0) {
        let list = res.data.list.map((resl, index) => {
          resl.index = index + 1
          resl.copylink = resl.link ? resl.link.link || '' : ''
          let coverUrl = ''
          let name = ''
          if (resl.content.type === 2) {
            coverUrl = resl.content.url + '?x-oss-process=video/snapshot,t_1000,ar_auto'
          } else if (resl.content.url) {
            coverUrl = resl.content.url
          }
          resl.type = resl.content.type
          if (resl.content.name || resl.content.displayName) {
            name = resl.content.displayName || resl.content.name || ''
          }
          resl.coverUrl = coverUrl || ''
          resl.name = name || ''
          let lengthindex = name.lastIndexOf('.')
          let editName = ''
          let suffix = ''
          if (lengthindex > -1) {
            editName = name.substr(0, lengthindex)
            suffix = name.substr(lengthindex)
          }
          resl.editName = editName || ''
          resl.suffix = suffix
          resl.isEdit = false
          resl.ischeck = false
          resl.objType = 1
          resl.sizes = resl.content.size ? this.$formatFileSize(resl.content.size) : '-'
          let createUser = resl.uploadUser
          resl.creater = createUser ? createUser.name || '' : ''
          let permissionType = '-'
          switch (resl.permissionType) {
            case 1:
              permissionType = '公开'
              break
            case 3:
              permissionType = '私密'
              break
            case 2:
              permissionType = '部分公开'
              break
          }
          resl.persiton = permissionType
          let groupName = ''
          let folder = resl.folder
          if (folder && folder.name) {
            groupName = folder.name
          }
          resl.groupName = groupName
          return resl
        })
        if (this.pageNum === 1) {
          this.dataList = list
        } else {
          this.dataList = this.dataList.concat(list)
        }
        if (list && list.length < 1) {
          $state.complete()
        } else {
          this.pageNum++
          if (this.isList) {
            this.$refs.scrollview.scrollTo(0, this.$refs.scrollview.scrollTop - 20)
          }
          this.$nextTick(() => {
            $state.loaded()
          })
        }
      } else {
        $state.complete()
      }
    },
    onBackClick () {
      if (this.breadcrumbList.length > 1) {
        let item = this.breadcrumbList[this.breadcrumbList.length - 2]
        this.folderId = item.folderid
        this.breadcrumbList.splice(this.breadcrumbList.length - 1, 1)
        this.subcurrectIndex = 0
      }
    },
    onModalsClose (status) {
      if (!status) {
        this.picshowUrl = ''
      }
    },
    onModalsMoveClose (status) {
      if (!status) {
        this.data1[0].selected = false
        this.data1[0].children.map(res => {
          res.selected = false
        })
        this.moveinfo = null
        this.selFloderInfo = null
      }
    },
    onUploadSuccess (e) {
      if (parseInt(e) === parseInt(this.folderId)) {
        this.reloadData()
      }
    },
    onExpordClick () {
      if (this.dataList && this.dataList.length) {
        let list = this.dataList.filter(res => {
          return res.ischeck
        }).map(res => {
          return {
            url: res.content.url,
            name: res.displayName || res.name || ''
          }
        })
        if (list && list.length) {
          if (list.length > 1) {
            handleBatchDownload(list)
          } else if (list.length === 1) {
            handleSimpleDownload(list[0])
          }
        }
      }
    },
    onFileDelClick () {
      if (this.dataList && this.dataList.length) {
        let list = this.dataList.filter(res => {
          return res.ischeck
        })
        if (list && list.length) {
          if (list.length === 1) {
            if (list[0].id < 1) {
              return this.$Message.info('系统文件夹，不可删除')
            }
          }
          list = list.filter(res => {
            return res.id > 0
          })
          this.$Modal.confirm({
            title: '删除',
            content: '<p>删除后将移入回收站？</p>',
            onOk: () => {
              let folderIdList = []
              let idList = []
              list.forEach(item => {
                if (item.objType === 1) {
                  idList.push(item.id)
                } else {
                  folderIdList.push(item.id)
                }
              })
              let olderIds = folderIdList.join(',')
              let ids = idList.join(',')
              deleteFolderAndFileToRecycle({
                folderIdList: olderIds,
                idList: ids
              }).then(res => {
                if (res.code === 0) {
                  this.$Message.info('删除成功!')
                  this.reloadData()
                } else {
                  if (res.msg) this.$Message.info(res.msg)
                }
              })


              // if (this.breadcrumbList && this.breadcrumbList.length === 1 && parseInt(this.folderGroup) === 1) {
              //   let params = {
              //     folderIdList: folderIdList,
              //     folderType: parseInt(this.folderType),
              //   }
              //   this.deleteFolderAndFileToRecycle(params).then(res => {
              //     if (res.code === 0) {
              //       this.reloadData()
              //     } else {
              //       this.$Message.info(res.msg)
              //     }
              //
              //   })
              // } else {
              //   let params = {
              //     folderType: parseInt(this.folderType),
              //     idList: folderIdList
              //   }
              //   this.delFile(params).then(res => {
              //     if (res.code === 0) {
              //       this.reloadData()
              //     } else {
              //       this.$Message.info(res.msg)
              //     }
              //   })
              // }
            },
            onCancel: () => {
            }
          })
        }
      }
    },
    onRenameClick () {
      if (this.dataList && this.dataList.length) {
        let list = this.dataList.filter(res => {
          return res.ischeck
        })
        if (list && list.length === 1) {
          list[0].isEdit = true
        }
      }
    },
    onFileEdit (row, index) {
      if (row.id < 1) {
        return this.$Message.info('系统文件夹，不可修改')
      }
      this.dataList[index].isEdit = true
    },
    ondel (row) {
      if (row.id < 1) {
        return this.$Message.info('系统文件夹，不可删除')
      }
      this.$Modal.confirm({
        title: '删除',
        content: '<p>删除后将移入回收站？</p>',
        onOk: () => {
          let list = [row]
          let folderIdList = []
          let idList = []
          list.forEach(item => {
            if (item.objType === 1) {
              idList.push(item.id)
            } else {
              folderIdList.push(item.id)
            }
          })
          let olderIds = folderIdList.join(',')
          let ids = idList.join(',')
          deleteFolderAndFileToRecycle({
            folderIdList: olderIds,
            idList: ids
          }).then(res => {
            if (res.code === 0) {
              this.$Message.info('删除成功!')
              this.reloadData()
            } else {
              if (res.msg) this.$Message.info(res.msg)
            }
          })

          // if (row.type === -1) {
          //   let params = {
          //     folderIdList: row.id,
          //     folderType: parseInt(this.folderType),
          //   }
          //   this.delFolder(params).then(res => {
          //     if (res.code === 0) {
          //       this.reloadData()
          //     } else {
          //       this.$Message.info(res.msg)
          //     }
          //
          //   })
          // } else {
          //   let params = {
          //     folderType: parseInt(this.folderType),
          //     idList: row.id
          //   }
          //   this.delFile(params).then(res => {
          //     if (res.code === 0) {
          //       this.reloadData()
          //     } else {
          //       this.$Message.info(res.msg)
          //     }
          //   })
          // }
        },
        onCancel: () => {
        }
      })
    },
    onClickRenameOk (item) {
      if (item.type !== -1) {
        let id = item.id
        let commitName = ''
        if (item.editName) {
          commitName = item.editName
          if (item.suffix) {
            commitName = commitName + item.suffix
          }
        } else {
          return this.$Message.info('请填写名称')
        }
        let content = item.content
        content.displayName = commitName || ''

        let folderId = 0
        if (item.folder) {
          folderId = item.folder.id
        }
        let params = {
          folderGroupId: item.folderGroupId,
          folderId: folderId,
          content: content
        }
        this.editFiles({
          id,
          params
        }).then(res => {
          if (res.code === 0) {
            this.reloadData()
          }
          this.$Message.info(res.msg)
        })
      } else {
        let id = item.id
        let params = {
          folderGroupId: parseInt(this.folderGroup),
          name: item.editName || '',
          permissionType: item.permissionType,
          sort: item.sort,
          userList: item.userList
        }

        this.editFolders({
          id,
          params
        }).then(res => {
          if (res.code === 0) {
            this.reloadData()
          }
          this.$Message.info(res.msg)
        })

      }
    },
    onClickRenameCancle (item, index) {
      item.isEdit = false
    },
    onTreeItemSelect (e) {
      if (e && e.length) {
        this.selFloderInfo = e[0]
      } else {
        this.selFloderInfo = null
      }
    },
    showAlbums (item) {
      if (item.type === -1 || item.type === 1 || item.type === 2) {
        return true
      } else {
        return false
      }
    },
    onCollectClick (row) {
      let params = {
        relationType: 1,
        relationIdList: [{
          id: row.id
        }]
      }
      if (row.collectStatus !== 1) {
        this.collectFile(params).then(res => {
          if (res.code === 0) {
            this.reloadData()
          }
        })
      } else {
        this.cancelCollect(params).then(res => {
          if (res.code === 0) {
            this.reloadData()
          }
        })
      }
    },
    showCollecStatus (row) {
      let collectStatus = row.collectStatus || 0
      if (collectStatus === 1) {
        return require('../../assets/common/ic_shoucang_selected.png')
      } else {
        return require('../../assets/common/ic_shoucang_default.png')
      }
    },
    onClickCollects () {
      let list = this.dataList.filter(res => {
        return res.ischeck
      })
      if (list && list.length) {
        let params = {
          relationType: 1,
        }
        let relationIdList = list.map(res => {
          return {
            id: res.id
          }
        })
        params.relationIdList = relationIdList
        this.collectFile(params).then(res => {
          if (res.code === 0) {
            this.reloadData()
          }
        })
      }

    },
    ...mapActions([
      'setMenuShowList',
      'addFolder',
      'getfolderList',
      'getEmployeeList',
      'getFileList',
      'editFiles',
      'editFolders',
      'delFolder',
      'delFile',
      'getFolderDetail',
      'tansferToFloder',
      'collectFile',
      'cancelCollect'
    ])
  }
}
</script>

<style lang="scss" scoped>
  .item-style:hover {
    background: #F4F4F4;
  }

  .item-style {
    .checkstyle {
      display: none;
    }

    .checkstyle[selectMode=true] {
      display: block;
    }
  }

  .item-style:hover {
    .checkstyle {
      display: block;
    }
  }

  .actives-item-style {
    /*background: #F1F8FF;*/
    color: #1385EA;
  }

  .actives-items-style {
    border-bottom: 2px solid #1385EA;
  }

  .rename {
    display: flex;
    align-items: center;

    img {
      width: 24px;
      height: 24px;
      margin-left: 10px;
      cursor: pointer;
    }
  }

  .total {
    font-size: 14px;
    color: #666;
    margin-top: 20px;
  }
</style>

<style>
  .ivu-table td.demo-table-info-column {
    text-align: left;
  }
</style>
<style scoped>
  .modal >>> .ivu-modal-body {
    padding: 0 !important;
  }
</style>

import axios from 'axios'
import JSZip from 'jszip'
import FileSaver from 'file-saver'

const getFile = url => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url,
      responseType: 'arraybuffer'
    }).then(data => {
      resolve(data.data)
    }).catch(error => {
      reject(error.toString())
    })
  })
}

export const handleBatchDownload = (filelist) => {
  let data = filelist // 需要下载打包的路径, 可以是本地相对路径, 也可以是跨域的全路径
  const zip = new JSZip()
  const cache = {}
  const promises = []
  data.forEach(item => {
    const promise = getFile(item.url).then(data => { // 下载文件, 并存成ArrayBuffer对象
      let name = item.name
      const arr_name = item.url.split('/')
      const file_name = name || arr_name[arr_name.length - 1] // 获取文件名
      zip.file(file_name, data, { binary: true }) // 逐个添加文件
      cache[file_name] = data
    })
    promises.push(promise)
  })

  Promise.all(promises).then(() => {
    zip.generateAsync({ type: 'blob' }).then(content => { // 生成二进制流
      FileSaver.saveAs(content, '打包下载.zip') // 利用file-saver保存文件
    })
  })
}

export const handleSimpleDownload = (file) => {
  getFile(file.url).then(data => {
    let name = file.name
    const arr_name = file.url.split('/')
    const file_name = name || arr_name[arr_name.length - 1]
    FileSaver.saveAs(new Blob([data], { type: 'application/octet-stream' }), file_name)
  })
}

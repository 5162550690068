<template>
  <div class="flex h-full" style="margin: 30px 30px 0">
    <div class=" h-full" style="width: 33.3%">
      <div class="w-full border o-y-auto" style="height: 428px">
        <div class=" flex a-center j-between b-b" style="padding: 18px 16px 10px">
           <span class="fs-14 d-block m-r-6">
             <slot name="dept">{{folderGroup === '1'?'相册':'分组'}}</slot>
           </span>
          <div @click="onAddClassify" class="flex a-center">
            <img alt="" src="../../assets/common/ic_add.png"
                 style="width: 16px;height: 16px;margin-right: 8px">
            <span class="fs-14" style="color: #0652DD">添加</span>
          </div>
        </div>
        <div class="p-t-10">
          <!--:move="onMove"-->

          <!--@update="onChangeSort"-->
          <div class="w-full p-h-15 lh-40 h-40 " style=" background: #EDF3FF;" v-if="addClassifyShow">
            <Input @on-blur="addPicClassify()" class="inputBoder" ref="classAdd" v-model="addClassify"></Input>
          </div>
          <draggable
            @end="onDragEnd"
            animation="300"
            element="ul"
            filter=".forbid"
            handle=".cursor-move"
            v-model="data1">
            <li :class="{'active': checkedClassifyId === item.id}" :key="index"
                @click="onClassSelect(item)"
                class="w-full p-h-15 lh-40 h-40 liHover flex a-center j-start"
                v-for="(item,index) in data1">
              <Icon class="cursor-move m-r-10"
                    custom="i-icon icon-drag"
                    size="20"/>
              <span class="flex-1" v-if="item.id !== valueId">{{ item.name }}</span>
              <Input @click.stop @on-blur="redactClassify(item)" autofocus class="inputBoder flex-1" ref="classedit"
                     style="width: 200px"
                     v-model="item.name "
                     v-show="item.id === valueId"></Input>

              <div :class="{'selectHover1':checkedClassifyId === item.id}" class="selectHover d-none">
                <img @click.stop="onChangeValue(item,index)" alt="" class="w-15 h-15 m-r-10"
                     src="../../assets/common/ic_xiugai.png">
                <img @click.stop="deleteClassify(item)" alt="" class="w-15 h-15"
                     src="../../assets/common/ic_shanchu1.png">
              </div>
            </li>
          </draggable>
        </div>
      </div>
    </div>
    <div class=" h-full" style="width: 33.3%">
      <div class="w-full b-b b-r b-t o-y-auto" style="height: 428px">
        <div class=" flex a-center j-between b-b" style="padding: 18px 16px 10px">
            <span class="fs-14 d-block m-r-6">
              <slot name="positione">权限设置</slot>
            </span>
        </div>
        <div>
          <ul class="p-t-10">
            <li :class="{'active': selectItem.permissionType === item.id}" :key="index"
                @click="onPersissionClick(item)"
                class="w-full p-h-15 lh-40 h-40 liHover flex a-center j-between"
                v-for="(item,index) in getPermissionList ">
              <span>{{ item.name }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class=" h-full" style="width: 33.3%">
      <div class="w-full b-b b-r b-t " style="height: 428px">
        <div class=" flex a-center j-between b-b" style="padding: 18px 16px 10px">
            <span class="fs-14 d-block m-r-6">
              <slot name="positione">成员设置</slot>
            </span>
        </div>
        <div class="o-y-auto o-hidden" style="height: 375px">
          <ul class="p-t-10">
            <li :key="index"
                @click="onUserSelectClick(item)"
                class="w-full p-h-15 lh-40 h-40 liHover flex a-center j-start"
                v-for="(item,index) in getUserList ">
              <input :checked="item.flag" onclick="return false" type="checkbox">
              <span class="m-l-5">{{item.name}} <span v-if="item.phone">({{item.phone}})</span></span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import draggable from 'vuedraggable'
import {deleteFolderAndFileToRecycle, editGroupSort} from '../../services/diskapi'

export default {
  name: 'FileGroupManger',
  components: {
    draggable
  },
  props: {
    folderGroup: [String, Number],
    folderType: [String, Number],
    open: Boolean,
    dataChange: Function
  },
  data () {
    return {
      addClassifyShow: false,           //初始化分类显示
      data1: [],
      groupLists: [],
      addClassify: '',                  //新增分类初始化,
      valueId: '',                      //修改分类名称Id
      innerMenus: [{
        id: 1,
        name: '公开'
      }, {
        id: 3,
        name: '私密'
      }, {
        id: 2,
        name: '部分可见'
      }],
      userList: [],
      myId: 0,
      checkedClassifyId: '',
      selectItem: null
    }
  },
  computed: {
    getGroup () {
      return {
        folderGroup: this.folderGroup,
        folderType: this.folderType,
        open: this.open
      }
    },
    getPermissionList () {
      if (this.selectItem && this.selectItem.id) {
        return this.innerMenus
      }
    },
    getUserList () {
      if (this.selectItem && this.selectItem.id && this.selectItem.permissionType === 2) {
        let selectIds = new Set()
        if (this.selectItem.userList) {
          this.selectItem.userList.map(res => {
            selectIds.add(res.id)
          })
        }
        return this.userList.map(res => {
          if (selectIds.has(res.id)) {
            res.flag = true
          } else {
            res.flag = false
          }
          return res
        })
      } else {
        return []
      }
    }
  },
  watch: {
    getGroup: {
      handler (newValue) {
        if (!newValue.open) {
          this.checkedClassifyId = ''
          this.selectItem = null
        } else {
          this.getSelFolderList().then(() => {
            if (this.data1 && this.data1.length && !this.selectItem) {
              this.selectItem = this.data1[0]
              this.checkedClassifyId = this.data1[0].id
            }
          })
        }

      },
      immediate: true,
      deep: true
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.myId = this.$store.state.user.userId
      this.getEmployeeLists()
    },
    getEmployeeLists () {
      let params = {
        pageNum: 1,
        pageSize: 1000
      }
      this.getEmployeeList(params).then(res => {
        if (res.code === 0) {
          this.userList = res.data.list.filter(child => {
            return child.id !== this.myId
          }).map(child => {
              child.role = this.getRole(child.roleType)
              child.flag = false
              return child
            }
          )
        }
      })
    },
    getSelFolderList () {
      return new Promise((async (resolve, reject) => {
        let params = {
          folderType: this.folderType,
          folderGroupId: parseInt(this.folderGroup),
          parentId: 0,
          permissionType: 0
        }
        let res = await this.getfolderList(params)
        if (res.code === 0) {
          let data1 = res.data.map(resl => {
            return {
              id: resl.id,
              name: resl.name,
              permissionType: resl.permissionType,
              folderType: resl.folderType,
              userList: resl.userList,
              sort: resl.sort
            }
          })
          this.data1 = data1 || []
          resolve()
        }
      }))
    },
    getRole (type) {
      let roleName = ''
      switch (type) {
        case 1:
          roleName = '超级管理员'
          break
        case 2:
          roleName = '管理员'
          break
        case 3:
          roleName = '普通用户'
          break
      }
      return roleName
    },
    onAddClassify () {
      this.addClassify = ''
      this.addClassifyShow = true
      this.$nextTick(() => {
        this.$refs.classAdd.focus()
      })
    },
    addPicClassify () {
      if (!this.addClassify) {
        this.addClassifyShow = false
        return
      }
      let params = {
        folderType: this.folderType,
        folderGroupId: parseInt(this.folderGroup),
        parentId: 0,
        name: this.addClassify,
        permissionType: 1,
        desc: '',
        userList: []
      }
      this.addFolders(params).then(res => {
        if (res.code === 0) {
          let id = res.data.id
          this.getSelFolderList().then(() => {
            this.checkedClassifyId = id
            let selList = this.data1.filter(child => {
              return child.id === this.checkedClassifyId
            })
            if (selList && selList.length) {
              this.selectItem = selList[0]
            }
            if (this.dataChange) {
              this.dataChange()
            }
          })
          this.addClassifyShow = false
        }
      })
    },
    deleteClassify (item) {
      this.$Modal.confirm({
        title: '删除' + (this.folderGroup === '1' ? '相册' : '分组'),
        content: '<p>是否删除该' + (this.folderGroup === '1' ? '相册' : '分组') + '？</p>',
        onOk: () => {
          let params = {
            folderIdList: item.id,
            folderType: parseInt(this.folderType),
          }
          if (parseInt(this.folderGroup) === 5) {
            this.delFolderGroup(params).then(res => {
              if (res.code === 0) {
                if (this.checkedClassifyId === item.id) {
                  this.checkedClassifyId = 0
                  this.selectItem = null
                  this.getSelFolderList().then(() => {
                    if (this.data1 && this.data1.length && !this.selectItem) {
                      this.selectItem = this.data1[0]
                      this.checkedClassifyId = this.data1[0].id
                    }
                  })
                } else {
                  let index = this.data1.findIndex(child => {
                    return child.id === item.id
                  })
                  this.data1.splice(index, 1)
                }
                if (this.dataChange) {
                  this.dataChange()
                }
              } else {
                this.$Message.info(res.msg)
              }

            })
          } else {
            let list = [item]
            let folderIdList = []
            let idList = []
            list.forEach(item => {
              if (item.objType === 1) {
                idList.push(item.id)
              } else {
                folderIdList.push(item.id)
              }
            })
            let olderIds = folderIdList.join(',')
            let ids = idList.join(',')
            deleteFolderAndFileToRecycle({
              folderIdList: olderIds,
              idList: ids
            }).then(res => {
              if (res.code === 0) {
                this.$Message.info('删除成功!')
                if (this.checkedClassifyId === item.id) {
                  this.checkedClassifyId = 0
                  this.selectItem = null
                  this.getSelFolderList().then(() => {
                    if (this.data1 && this.data1.length && !this.selectItem) {
                      this.selectItem = this.data1[0]
                      this.checkedClassifyId = this.data1[0].id
                    }
                  })
                } else {
                  let index = this.data1.findIndex(child => {
                    return child.id === item.id
                  })
                  this.data1.splice(index, 1)
                }
                if (this.dataChange) {
                  this.dataChange()
                }
              } else {
                if (res.msg) this.$Message.info(res.msg)
              }
            })

            // this.delFolder(params).then(res => {
            //   if (res.code === 0) {
            //     if (this.checkedClassifyId === item.id) {
            //       this.checkedClassifyId = 0
            //       this.selectItem = null
            //       this.getSelFolderList().then(() => {
            //         if (this.data1 && this.data1.length && !this.selectItem) {
            //           this.selectItem = this.data1[0]
            //           this.checkedClassifyId = this.data1[0].id
            //         }
            //       })
            //     } else {
            //       let index = this.data1.findIndex(child => {
            //         return child.id === item.id
            //       })
            //       this.data1.splice(index, 1)
            //     }
            //     if (this.dataChange) {
            //       this.dataChange()
            //     }
            //   } else {
            //     this.$Message.info(res.msg)
            //   }
            //
            // })
          }
        },
        onCancel: () => {
        }
      })
    },
    onClassSelect (item) {
      this.selectItem = item
      this.checkedClassifyId = item.id
    },
    redactClassify (item) {
      if (!item.name) {
        return this.$Message.info('请输入名称')
      }
      let id = item.id
      let userList = item.userList.map(res => {
        return {
          id: res.id
        }
      })
      if (userList && userList.length) {
        let user = new Set()
        userList.map(child => {
          user.add(child.id)
        })
        if (!user.has(this.myId)) {
          userList.push({
            id: this.myId
          })
        }
      } else {
        userList.push({
          id: this.myId
        })
      }
      let params = {
        folderGroupId: parseInt(this.folderGroup),
        name: item.name || '',
        permissionType: item.permissionType || 1,
        userList: userList || []
      }
      let par = {
        id,
        params
      }
      this.editFolderss(par).then(res => {
        if (this.dataChange) {
          this.dataChange()
        }
        this.valueId = ''
      })
    },
    onChangeValue (item, index) {
      this.valueId = item.id
      this.$nextTick(() => {
        this.$refs.classedit[index].focus()
      })
    },

    /**
     *  编辑分组
     */
    editFolderss (par) {
      return new Promise(((resolve, reject) => {
        this.editFolders(par).then(res => {
          if (res.code === 0) {
            resolve(res)
          } else {
            this.$Message.info(res.msg)
          }
        })
      }))
    },
    /**
     * 新增分组
     * @param params
     * @returns {Promise<any>}
     */
    addFolders (params) {
      return new Promise(((resolve, reject) => {
        this.addFolder(params).then(res => {
          if (res.code === 0) {
            resolve(res)
          } else {
            this.$Message.info(res.msg)
          }
        })
      }))
    },
    onPersissionClick (item) {
      let id = this.checkedClassifyId
      let userList = this.userList.filter(res => {
        return res.flag
      }).map(res => {
        return {
          id: res.id
        }
      })
      userList.push({
        id: this.myId
      })
      let params = {
        folderGroupId: parseInt(this.folderGroup),
        name: this.selectItem.name || '',
        permissionType: item.id || 1,
        userList: userList || []
      }
      let par = {
        id,
        params
      }
      this.editFolderss(par).then(res => {
        if (this.dataChange) {
          this.dataChange()
        }
        this.selectItem.permissionType = item.id
      })
    },
    onUserSelectClick (item) {
      let id = this.checkedClassifyId
      let userList = this.userList.filter(res => {
        return res.flag
      }).map(res => {
        return {
          id: res.id
        }
      })
      if (item.flag) {
        userList = userList.filter(res => {
          return res.id !== item.id
        })
      } else {
        userList.push({
          id: item.id
        })
      }
      userList.push({
        id: this.myId
      })
      let params = {
        folderGroupId: parseInt(this.folderGroup),
        name: this.selectItem.name || '',
        permissionType: this.selectItem.permissionType || 1,
        userList: userList || []
      }
      let par = {
        id,
        params
      }
      this.editFolderss(par).then(() => {
        if (item.flag) {
          item.flag = false
          this.selectItem.userList = this.selectItem.userList.filter(child => {
            return child.id !== item.id
          })
        } else {
          item.flag = true
          this.selectItem.userList.push(item)
        }
        if (this.dataChange) {
          this.dataChange()
        }
      })
    },
    onDragEnd (param) {
      let newIndex = param.newIndex
      let oldIndex = param.oldIndex
      let folderId = this.data1[newIndex].id
      if (oldIndex === newIndex) {
        return
      }
      let sort = 0
      if (newIndex > oldIndex) {
        sort = this.data1[newIndex - 1].sort
      } else {
        sort = this.data1[newIndex + 1].sort
      }
      editGroupSort({folderId, sort}).then(res => {
        if (res.code === 0) {
          this.getSelFolderList().then(() => {
            if (this.checkedClassifyId && this.selectItem) {
              if (this.data1 && this.data1.length) {
                let ids = this.data1.filter(res => {
                  return res.id === this.selectItem.id
                })
                if (!(ids && ids.length)) {
                  this.selectItem = this.data1[0]
                  this.checkedClassifyId = this.data1[0].id
                } else {
                  this.selectItem = ids[0]
                  this.checkedClassifyId = ids[0].id
                }
              }
            } else {
              if (this.data1 && this.data1.length && !this.selectItem) {
                this.selectItem = this.data1[0]
                this.checkedClassifyId = this.data1[0].id
              }
            }
          })
          if (this.dataChange) {
            this.dataChange()
          }
        }
      })
    },
    ...mapActions([
      'getEmployeeList',
      'getfolderList',
      'addFolder',
      'editFolders',
      'delFolder',
      'delFolderGroup'
    ])
  }
}
</script>

<style lang="scss" scoped>

  .active {
    background: #EDF3FF;
    color: #0652DD;
  }


  .liHover:hover {
    background: #EDF3FF;

  }

  .liHover:hover .selectHover {
    display: block;

  }

  .selectHover1 {
    display: block;

  }
</style>

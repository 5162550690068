import { render, staticRenderFns } from "./Albums.vue?vue&type=template&id=23e8727f&scoped=true&xmlns%3Av-clipboard=http%3A%2F%2Fwww.w3.org%2F1999%2Fxhtml&"
import script from "./Albums.vue?vue&type=script&lang=js&"
export * from "./Albums.vue?vue&type=script&lang=js&"
import style0 from "./Albums.vue?vue&type=style&index=0&id=23e8727f&lang=scss&scoped=true&"
import style1 from "./Albums.vue?vue&type=style&index=1&lang=css&"
import style2 from "./Albums.vue?vue&type=style&index=2&id=23e8727f&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23e8727f",
  null
  
)

export default component.exports